<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="cards">
          <form id="createOrder" @submit="createOrder">
            <CCardHeader>
              <CCol col="6" class="styleHeader">
                {{ $t("message.createOrder") }}</CCol
              >
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6" class="m-auto orderstype">
                  {{ $t("message.orderType") }}
                  <span class="star">*</span>
                  <CInput
                    :label="$t('message.sales')"
                    class="sm"
                    type="radio"
                    name="orderType"
                    @change="selectOrder(1)"
                    value="1"
                    id="order-type"
                  />
                  <CInput
                    :label="$t('message.purchase')"
                    type="radio"
                    name="orderType"
                    @change="selectOrder(2)"
                    value="2"
                    id="order-type"
                  />
                </CCol>
              </CRow>
              <hr />
              <div v-if="selectedTypeOfOrder === 1">
                <CRow>
                  <h4>{{ $t("message.saleOrderNote") }}</h4>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <label for="suppliers">
                      {{ $t("message.office") }}
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selectedOffice"
                      id="office-id"
                      :options="offices"
                      @select="onOfficeChange"
                      :searchable="true"
                      :close-on-select="true"
                      :placeholder="$t('message.select')"
                      :selectLabel="$t('message.pressSelect')"
                      label="name"
                      track-by="id"
                      required="required"
                    >
                      <span slot="noOptions">{{
                        $t("message.emptyList")
                      }}</span>
                      <span slot="noResult">{{ $t("message.noResult") }} </span>
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol sm="6">
                    <div class="required form-group">
                      <label>
                        {{ $t("message.customer") }}
                      </label>
                      <CButton
                        type="button"
                        @click="showModal = true"
                        class="copy add-btn"
                      >
                        +
                      </CButton>
                      <multiselect
                        id="customer"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="selectCustomer"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      :label="$t('message.balance')"
                      id="balance"
                      v-model="balance"
                      disabled
                      class="mt-3"
                      :placeholder="$t('message.balance')"
                    />
                  </CCol>
                </CRow>
              </div>
              <div v-else-if="selectedTypeOfOrder === 2">
                <CRow>
                  <h4>{{ $t("message.purchaseOrderNote") }}</h4>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <label for="suppliers">
                      {{ $t("message.supplier") }}
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selectedSupplier"
                      id="supplier_id"
                      :options="suppliers"
                      :searchable="true"
                      @select="onSuppliersChange($event)"
                      :close-on-select="true"
                      :placeholder="$t('message.select')"
                      :selectLabel="$t('message.pressSelect')"
                      label="name"
                      track-by="name"
                      required="required"
                    >
                      <span slot="noOptions">{{
                        $t("message.emptyList")
                      }}</span>
                      <span slot="noResult">{{ $t("message.noResult") }} </span>
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <div class="required form-group">
                      <label>
                        {{ $t("message.customer") }}
                      </label>
                      <CButton
                        type="button"
                        @click="showModal = true"
                        class="copy add-btn"
                      >
                        +
                      </CButton>
                      <multiselect
                        id="customer"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="selectCustomer"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      :label="$t('message.balance')"
                      id="balance"
                      v-model="balance"
                      disabled
                      class="mt-3"
                      :placeholder="$t('message.balance')"
                    />
                  </CCol>
                </CRow>
              </div>
              <hr />
              <CRow class="mt-3">
                <CCol col="6">
                  <h3 class="mb-3">{{ $t("message.products") }}</h3>
                  <div class="line"></div>
                </CCol>
              </CRow>
              <button
                class="btn btn-primary px-3 py-2"
                type="button"
                @click="addProduct"
              >
                {{ $t("message.addProductToOrder") }}
              </button>
              <CRow class="mt-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th width="14%">
                        {{ $t("message.category") }}<span class="star">*</span>
                      </th>
                      <th width="17%">
                        {{ $t("message.product") }}<span class="star">*</span>
                      </th>
                      <th width="14%" v-if="selectedTypeOfOrder === 1">
                        {{ $t("message.transferNumber")
                        }}<span class="star">*</span>
                      </th>
                      <th width="10%">
                        {{ $t("message.price") }}<span class="star">*</span>
                      </th>
                      <th width="16%">
                        {{ $t("message.quantity") }}<span class="star">*</span>
                      </th>
                      <th width="16%">{{ $t("message.total") }}</th>
                      <th width="5%">{{ $t("message.deleteProduct") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in products" :key="index">
                      <td class="width"  width="23%">
                        <multiselect
                          class="required"
                          id="category_id"
                          v-model="product.category"
                          :options="categories"
                          :searchable="true"
                          @select="onCategoriesChange($event, index)"
                          :close-on-select="true"
                          :placeholder="$t('message.select')"
                          :selectLabel="$t('message.pressSelect')"
                          label="name"
                          track-by="id"
                          required="required"
                        >
                          <span slot="noOptions">{{
                            $t("message.emptyList")
                          }}</span>
                          <span slot="noResult"
                            >{{ $t("message.noResult") }}
                          </span>
                        </multiselect>
                      </td>
                      <td class="width" width="27%">
                        <multiselect
                          class="required"
                          id="product_id"
                          v-model="product.product"
                          :options="product.productOptions"
                          :searchable="true"
                          @select="currentProduct($event, index)"
                          :close-on-select="true"
                          :placeholder="$t('message.select')"
                          :selectLabel="$t('message.pressSelect')"
                          label="name"
                          track-by="id"
                          required="required"
                        >
                          <span slot="noOptions">{{
                            $t("message.emptyList")
                          }}</span>
                          <span slot="noResult"
                            >{{ $t("message.noResult") }}
                          </span>
                        </multiselect>
                      </td>
                      <td v-if="selectedTypeOfOrder === 1">
                        <CInput
                          type="number"
                          id="transfer_n umber"
                          min="1"
                          disabled
                          class="required"
                          v-model="product.transferNumber"
                        />
                      </td>
                      <td>
                        <CInput
                          type="text"
                          id="price"
                          @input="calcPrice($event, index)"
                          class="required"
                          v-model="product.price"
                          required
                        />
                      </td>
                      <td>
                        <CInput
                          type="number"
                          id="quantity"
                          class="required"
                          min="1"
                          @input="calcTotal($event, index)"
                          v-model="product.quantity"
                          :invalidFeedback="errors.quantity"
                          required
                        />
                      </td>
                      <td>
                        <CInput
                          type="number"
                          disabled
                          id="total"
                          min="0"
                          v-model="product.total"
                          required
                        />
                      </td>
                      <td>
                        <CButton
                          class="removeItem"
                          type="button"
                          size="sm"
                          color="danger"
                          @click="removeProduct(index)"
                        >
                          {{ $t("message.clear") }}
                        </CButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle" />
                {{ $t("message.save") }}</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger">
                <CIcon name="cil-ban" />{{ $t("message.clear") }}
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
    <Modal v-model="showModal">
      <NewCustomer />
    </Modal>
  </div>
</template>

<script>
import NewCustomer from '@/components/NewCustomer.vue'
import $ from 'jquery'
import swal from 'sweetalert'

export default {
  name: 'CreateOrder',
  data: function () {
    return {
      checked: '',
      showModal: false,
      order: {},
      products: [],
      customers: [],
      categories: [],
      selectedCategory: '',
      suppliers: [],
      selectedSupplier: '',
      selectedProduct: '',
      selectedTypeOfOrder: '',
      customerPhone: '',
      name: '',
      balance: '',
      customer: null,
      offices: [],
      selectedOffice: {},
      errors: []
    }
  },
  components: {
    NewCustomer
  },
  created () {
    const customers = []
    this.$http.get(`${this.$hostUrl}customers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        customers.push({
          id: value.id,
          name: value.name + ' - ' + value.phone,
          phone: value.phone,
          balance: value.balance
        })
      })
      this.customers = customers
    })
    const suppliers = []
    this.$http.get(`${this.$hostUrl}suppliers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        suppliers.push({ id: value.id, name: value.name })
      })

      this.suppliers = suppliers
    })
    const offices = []
    this.$http.get(`${this.$hostUrl}offices`).then((response) => {
      $.each(response.data.data, function (key, value) {
        if (value.id !== 2) {
          offices.push({ id: value.id, name: value.name })
        }
      })
      this.offices = offices
    })
    const categories = []
    this.$http.get(`${this.$hostUrl}categories`).then((response) => {
      $.each(response.data.data, function (key, value) {
        categories.push({ id: value.id, name: value.name })
      })
      this.categories = categories
    })
    const products = []
    this.$http.get(`${this.$hostUrl}products`).then((response) => {
      $.each(response.data.data, function (key, value) {
        products.push({ id: value.id, name: value.name })
      })
      this.products[0].productOptions = products
    })
  },
  mounted: function () {
    this.oneProduct() // method1 will execute at pageload
  },
  methods: {
    round2Decimals (num) {
      num.toFixed(2)
    },
    search () {
      console.log('this.customerPhone', this.customerPhone)
      const current = this
      current.name = ''
      current.balance = ''
      current.customer = null
      if (this.customerPhone.length === 10) {
        this.$http
          .get(`${this.$hostUrl}customers-search?phone=${this.customerPhone}`)
          .then((response) => {
            current.customer = response.data.data
            current.name = current.customer.name
            current.balance = current.customer.balance
          })
      }
    },
    selectCustomer (event) {
      const current = this
      current.name = event.name
      current.balance = event.balance
      console.log('event', event)
      current.customerPhone = event.phone
    },
    selectOrder (event) {
      this.selectedTypeOfOrder = event
    },
    onOfficeChange () {
      this.customer = {}
      this.customerPhone = ''
      this.name = ''
      this.balance = ''
      this.selectedSupplier = {}
    },
    onSuppliersChange ($event) {
      this.customer = {}
      this.customerPhone = ''
      this.name = ''
      this.balance = ''
      this.selectedOffice = {}
      this.products = []
      this.products.push({
        product: {
          product_id: null,
          supplier_id: null,
          price: null
        },
        category: {
          id: null,
          name: null
        },
        productOptions: [],
        id: 1,
        quantity: 1,
        price: null,
        total: 0,
        selectedCategory: '',
        transferNumber: ''
      })
    },
    onCategoriesChange ($event, index) {
      const productOptions = []
      this.products[index].productOptions = []
      this.products[index].product = {}
      this.products[index].price = ''
      this.products[index].total = ''
      this.products[index].transferNumber = ''
      this.products[index].quantity = 1
      this.products[index].category = $event
      if (this.selectedTypeOfOrder === 2) {
        if (this.selectedSupplier.id) {
          this.$http
            .get(
            `${this.$hostUrl}products-filter?category_id=${$event.id}&supplier_id=${this.selectedSupplier.id}`
            )
            .then((response) => {
              $.each(response.data.data, function (key, value) {
                productOptions.push({
                  id: value.id,
                  name: value.name,
                  supplier_id: value.supplier_id,
                  price: value.price,
                  category_id: value.id
                })
              })
              this.products[index].productOptions = productOptions
            })
        } else {
          this.$http
            .get(`${this.$hostUrl}category/${$event.id}/products?customer_id=${this.customer.id}`)
            .then((response) => {
              $.each(response.data.data.category.products, function (key, value) {
                productOptions.push({
                  id: value.id,
                  name: value.name,
                  supplier_id: value.supplier_id,
                  price: value.price,
                  category_id: value.id
                })
              })
              this.products[index].productOptions = productOptions
            })
        }
      } else {
        console.log('products', this.products[index].productOptions)
        this.$http
          .get(`${this.$hostUrl}category/${$event.id}/products?customer_id=${this.customer.id}`)
          .then((response) => {
            $.each(response.data.data.category.products, function (key, value) {
              productOptions.push({
                id: value.id,
                name: value.name,
                supplier_id: value.supplier_id,
                price: value.price,
                category_id: value.id
              })
            })
            this.products[index].productOptions = productOptions
          })
      }
    },
    onChange ($event) {
      this.selectedSupplier = {}
      this.selectedOffice = {}
    },
    oneProduct: function () {
      this.products.push({
        product: {
          product_id: null,
          supplier_id: null,
          price: null,
          category_id: null
        },
        category: {
          id: null,
          name: null
        },
        productOptions: [],
        id: this.products.length + 1,
        quantity: 1,
        price: null,
        total: 0,
        selectedCategory: '',
        transferNumber: '',
        category_id: null
      })
    },
    addProduct () {
      this.products.push({
        product: {
          product_id: null,
          supplier_id: null,
          price: null,
          category_id: null
        },
        category: {
          id: null,
          name: null
        },
        productOptions: [],
        id: this.products.length + 1,
        quantity: 1,
        price: null,
        total: 0,
        selectedCategory: '',
        transferNumber: '',
        category_id: null
      })
    },
    removeProduct (index) {
      this.products.splice(index, 1)
    },
    currentProduct ($event, index) {
      const supplierId = $event.supplier_id
      if (supplierId === 6) {
        this.products[index].transferNumber = this.customer.zain_transfer_no
      } else if (supplierId === 12) {
        this.products[index].transferNumber = this.customer.mtn_transfer_no
      } else if (supplierId === 1) {
        this.products[index].transferNumber = this.customer.digitel_transfer_no
      } else if (supplierId === 4) {
        this.products[index].transferNumber = this.customer.momo_transfer_no
      } else if (supplierId === 9) {
        this.products[index].transferNumber = this.customer.mgurush_transfer_no
      }
      this.products[index].category_id = $event.category_id
      this.products[index].price = $event.price
      this.products[index].total =
        (parseFloat($event.price) * parseFloat(this.products[index].quantity)).toFixed(2)
    },
    calcTotal ($event, index) {
      this.products[index].total =
       (parseFloat($event) * parseFloat(this.products[index].price)).toFixed(2)
    },
    calcPrice ($event, index) {
      this.products[index].total =
        parseFloat($event) * parseFloat(this.products[index].quantity)
    },
    createOrder: function (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('order_type_id', this.selectedTypeOfOrder)
      if (this.selectedTypeOfOrder === 1) {
        if (this.customer.id) {
          formData.append('customer_id', (this.customer.id))
        } else if (this.selectedOffice.id) {
          formData.append('office_id', this.selectedOffice.id)
        } else {
          swal(this.$t('message.saleOrderNote'))
          return 0
        }
      } else {
        if (this.selectedSupplier.id) {
          formData.append('supplier_id', this.selectedSupplier.id)
        } else if (this.customer.id) {
          formData.append('customer_id', this.customer.id)
        } else {
          swal(this.$t('message.purchaseOrderNote'))
          return 0
        }
      }
      this.sendProducts = []
      for (let i = 0; i <= this.products.length - 1; i++) {
        this.sendProducts.push({
          product_id: this.products[i].product.id,
          price: this.products[i].price,
          quantity: this.products[i].quantity,
          transfer_number: this.products[i].transferNumber ?? '',
          total: this.products[i].total
        })
      }
      formData.append('products', JSON.stringify(this.sendProducts))
      this.errors = this.postRequest(
        formData,
        this.$hostUrl + 'orders',
        '/orders'
      )
    }
  }
}
</script>
<style >
input[type="radio"]{
font-size: 7px !important;
cursor: pointer;
}
input[type="radio"]:focus{
box-shadow: unset !important;
}
.orderstype{
  display: flex;
  gap:37px;
}
.orderstype .star{
margin-right: -33px;
}
</style>
